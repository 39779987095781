import { Text } from '@latitude/text';
import { Tooltip } from '@latitude/tooltip';
import { useMemo } from 'react';
import { PORTO_SELF_REGISTRATION_PAGES } from 'components/porto/Signup/helpers';
import css from './styles.module.scss';
const WhyIsThisImportantComponent = () => <div className="max-w-fit" data-sentry-component="WhyIsThisImportantComponent" data-sentry-source-file="PageHeader.tsx">
    <Tooltip className="text-clip" side="bottom" text={<div className={css.tooltipContainer}>
          <Text variant="labelMedium" className={css.tooltipTextContainer}>
            We collect this information to comply with relevant laws and
            regulations. We only use it for business purposes and do not share
            it for any other reason. Failure to provide legitimate information
            may cause disruptions to the account.
          </Text>
        </div>} data-sentry-element="Tooltip" data-sentry-source-file="PageHeader.tsx">
      <div data-testid="tooltip-text">
        <Text variant="labelMedium" className={css.tooltipColor} data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">
          Why is this important?
        </Text>
      </div>
    </Tooltip>
  </div>;
const LearnMoreComponent = () => <Text variant="labelMedium" className="max-w-fit" data-sentry-element="Text" data-sentry-component="LearnMoreComponent" data-sentry-source-file="PageHeader.tsx">
    <Tooltip side="right" className="text-clip" text={<div className={css.tooltipContainer}>
          <Text variant="labelMedium" className={css.tooltipTextContainer}>
            Authorized users may perform sensitive operations, such as:
          </Text>
          <div className={css.tooltipList}>
            <Text variant="labelMedium">&#x2022; Creating vaults</Text>
            <Text variant="labelMedium">&#x2022; Withdrawing assets</Text>
            <Text variant="labelMedium">
              &#x2022; Interacting with dApps and smart contracts
            </Text>
            <Text variant="labelMedium">&#x2022; Adding or removing users</Text>
            <Text variant="labelMedium">&#x2022; Allowlisting addresses</Text>
            <Text variant="labelMedium">&#x2022; Decrypting wallet keys</Text>
          </div>
        </div>} data-sentry-element="Tooltip" data-sentry-source-file="PageHeader.tsx">
      <Text variant="labelMedium" className={css.tooltipColor} data-testid="tooltip-text" data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">
        Learn more
      </Text>
    </Tooltip>
  </Text>;
type Props = {
  page: PORTO_SELF_REGISTRATION_PAGES;
};
function PageHeader({
  page
}: Props) {
  const {
    description,
    step,
    title,
    TooltipComponent
  } = useMemo(() => {
    switch (page) {
      case PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE:
        return {
          description: 'Start by entering a few personal details.',
          step: 1,
          title: 'Sign up for porto',
          TooltipComponent: <WhyIsThisImportantComponent />
        };
      case PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS:
        return {
          description: "Now, fill in your organization's information.",
          step: 2,
          title: 'Organization details',
          TooltipComponent: <WhyIsThisImportantComponent />
        };
      default:
        return {
          description: 'Add more authorized users from your organization to enable quorum-based approvals. These users will have full administrative control by default.',
          step: 3,
          title: 'Add your team',
          TooltipComponent: <LearnMoreComponent />
        };
    }
  }, [page]);
  return <div className={css.container} data-sentry-component="PageHeader" data-sentry-source-file="PageHeader.tsx">
      <Text data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">Step {step} of 3</Text>
      <Text variant="headingLarge" className={css.notMobile} data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">
        {title}
      </Text>
      <Text variant="headingMedium" className={css.mobile} data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">
        {title}
      </Text>
      <Text variant="labelMedium" className={page === PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM ? 'flex flex-col' : 'flex gap-0.5'} data-sentry-element="Text" data-sentry-source-file="PageHeader.tsx">
        {description}
        {TooltipComponent}
      </Text>
    </div>;
}
export default PageHeader;